import React from "react";
import "./Step1.css";
import step1Img from "../../../media/pepe_image.png";

const Step1 = () => {
  return (
    <div className="step1_container">
      <p className="step1_Text">
        <span className="step1_markedText">
          Join the Pepe's FrogBar Revolution:
        </span>{" "}
        Be part of the most anticipated meme coin launch of Q4 2024.
      </p>
      <div className="step1_imageContainer">
        <img src={step1Img} alt="step1Img" className="step1Image" />
      </div>
    </div>
  );
};

export default Step1;
