import React from "react";
// import step3Img from "../../../media/stepImg1.png";
import newstep3Img from "../../../media/newstep3.png";
import "./Step3.css";
const Step3 = () => {
  return (
    <>
      <div className="step3_container">
        <p className="step3_Text">
          <span style={{ color: "green", fontWeight: "bold" }}>Looking for the next 1000X?</span>{" "}<br/>
          Join the private sale through our exclusive packages. Earn{" "}
          <span style={{ color: "yellow" }}>Pepe Credits,</span> which can be
          redeemed for tokens upon listing.
        </p>
        <div className="step3_imageContainer">
          <img src={newstep3Img} alt="step3Img" className="step3Image" />
        </div>
      </div>
    </>
  );
};

export default Step3;
