import React, { useContext, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import "./EnergyPopup.css";
import energyFull from "../../../media/energy-bettery.png";
import energy90 from "../../../media/energy-90.png";
import energy50 from "../../../media/energy-50.png";
import energy30 from "../../../media/energy-30.png";
import energy10 from "../../../media/energy-10.png";
import { UserContext } from "../../../context/UserContext";
import { energyRankMapping, SERVER_ENDPOINT } from "../../../utils/constants";
import { AuthContext } from "../../../context/AuthContext";
import toast from "react-hot-toast";
import moment from "moment/moment";

const EnergyPoup = ({ setShowEnergyModal }) => {
  const { userEnergy, user, setUserEnergy, setUser } = useContext(UserContext);
  const { jwt } = useContext(AuthContext);
  const { rank, dailyCount, energyCoolDownActive, lastEnergyMaxAt } = user;
  const [remainingTime, setRemainingTime] = useState("");
  const [isRecharging, setIsRecharging] = useState(false);

  useEffect(() => {
    let timer;
    const updateTimer = () => {
      if (energyCoolDownActive && lastEnergyMaxAt) {
        const now = moment();
        const cooldownEnd = moment(lastEnergyMaxAt).add(1, 'hour');
        const duration = moment.duration(cooldownEnd.diff(now));

        if (duration.asSeconds() <= 0) {
          setUser(prevUser => ({ ...prevUser, energyCoolDownActive: false }));
          setRemainingTime("");
        } else {
          const minutes = duration.minutes().toString().padStart(2, '0');
          const seconds = duration.seconds().toString().padStart(2, '0');
          setRemainingTime(`${minutes}:${seconds}`);
        }
      } else {
        setRemainingTime("");
      }
    };

    updateTimer();
    timer = setInterval(updateTimer, 1000);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [energyCoolDownActive, lastEnergyMaxAt, setUser]);

  const MAX_ENERGY = energyRankMapping[rank];
  const ratio = userEnergy / MAX_ENERGY;
  const img =
    ratio === 1
      ? energyFull
      : ratio > 0.9
      ? energy90
      : ratio > 0.5
      ? energy50
      : ratio > 0.3
      ? energy30
      : energy10;
  const backgroundStyle = {
    background:
      ratio < 0.5
        ? "radial-gradient(circle, rgba(217, 30, 24, 1) -25%, rgba(34, 18, 55, 1) 44%, rgba(34, 18, 55, 1) 100%)"
        : "radial-gradient(circle, rgba(87, 189, 78, 1) -25%, rgba(34, 18, 55, 1) 35%, rgba(34, 18, 55, 1) 100%)",
  };

  async function handleEnergyFull() {
    setIsRecharging(true);
    try {
      const response = await fetch(`${SERVER_ENDPOINT}/v1/user/energyMax`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      });
      const parsedResponse = await response.json();
      if (parsedResponse.status === "SUCCESS") {
        setUserEnergy(parsedResponse.data.energy);
        setUser((user) => ({
          ...user,
          dailyCount: parsedResponse.data.dailyCount,
          lastEnergyMaxAt: parsedResponse.data.lastEnergyMaxAt,
          energyCoolDownActive: true,
        }));
        toast.success("Energy Refilled");
      } else throw new Error("User Limited reached for energy refill");
    } catch (error) {
      console.log(error);
      toast.error("Reached Daily Limit");
    } finally {
      setIsRecharging(false);
    }
  }

  const getButtonText = () => {
    if (isRecharging) return "Recharging...";
    if (energyCoolDownActive) {
      return (5 - (dailyCount || 0)) === 0 ? "Limit Reached" : `Cooldown: ${remainingTime}`;
    }
    return "Recharge Energy";
  };

  return (
    <div className="energyPopup" style={backgroundStyle}>
      <div className="energyPopupContent">
        <div className="energyPopupTop">
          <span className="energyPopupTitle">Your Energy</span>
          <span className="closeIcon" onClick={() => setShowEnergyModal(false)}>
            <MdClose className="closeIcon" />
          </span>
        </div>
        <div className="energyPopupDivider"></div>
        <div className="energyContentBox">
          <img src={img} alt="" className="energyImg" />
        </div>

        <div className="enegeryBottom">
          <p className="energyHelth">
            {Math.floor(userEnergy)}/{MAX_ENERGY}
          </p>
          <button 
            disabled={energyCoolDownActive || isRecharging} 
            onClick={handleEnergyFull} 
            className={`enegeryBottomBox ${isRecharging ? 'recharging' : ''}`}
          >
            {getButtonText()}
          </button>
          <span className="infoEnergy">{5 - (dailyCount || 0)}/5 Available</span>
        </div>
      </div>
    </div>
  );
};

export default EnergyPoup;