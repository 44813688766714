import React, { useEffect, useRef, useState } from "react";
import styles from "./Popup.module.css";
import { useSpring, animated } from "@react-spring/web";

const Popup = (props) => {
  const [visible, setVisible] = useState(false);
  const [showPop, setShowPop] = useState(false);

  const profileBoxAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0.1 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      // await next({ backdropFilter: "blur(40px)" });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(0%)",
      });
    },
    config: { duration: 500 },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPop(true);
    }, 6000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <animated.div style={{ ...profileBoxAnimation }}>
      <div className={`${styles.body} ${showPop ? styles.hidden : ""}`}>
        <div className={styles.content}>
          <div className={styles.imgBox}>
            <img src={props.src} className={styles.silverPepe} />
          </div>
          <div className={styles.msgBox}>
            <div className={styles.greet}>{props.greet}</div>
            <div className={styles.greetMsg}>{props.greetMsg}</div>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default Popup;
