import styles from "./ProfileBar.module.css";
import React, { useContext, useState, useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import connIcon from "../../../media/connIcon.png";
import PEPEcoin from "../../../media/PEPEcoin.png";
import levelIcon from "../../../media/level.png";
import level1Icon from "../../../media/level1.png";
import level2Icon from "../../../media/level2.png";
import level3Icon from "../../../media/level3.png";
import level4Icon from "../../../media/level4.png";
import level5Icon from "../../../media/level5.png";
import level6Icon from "../../../media/level6.png";
import level7Icon from "../../../media/level7.png";
import level8Icon from "../../../media/level8.png";
import level9Icon from "../../../media/level9.png";
import bluecheck from "../../../media/bluecheck.png";
import level10Icon from "../../../media/level10.png";
import bronzeIcon from "../../../media/bronze.png";
import silverIcon from "../../../media/silver.png";
import goldIcon from "../../../media/gold.png";
import platinumIcon from "../../../media/platinum.png";
import diamondIcon from "../../../media/diamond.png";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import rookie from "../../../media/rookie.png";
import ppLevel from "../../../media/ppLevel.png";
import frogImg from "../../../media/frogImg.png";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { useTonAddress } from "@tonconnect/ui-react";
import { AuthContext } from "../../../context/AuthContext";
import { getReadableNumber } from "../../../utils/helpers";

const ProfileBar = ({ }) => {
  const [showConnect, setShowConnect] = useState(false);
  const [barWidth, setBarWidth] = useState(70);
  const { user, showConnectWallet, setShowConnectWallet } =
    useContext(UserContext);
  const { jwt } = useContext(AuthContext);
  const [userProfileImg, setUserProfileImg] = useState(user.profileImgUrl);
  const connectedAddress = useTonAddress();
  const navigate = useNavigate();

  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
  }, [user]);

  const handleConnect = () => {
    console.log(connectedAddress);
    if (connectedAddress !== "") {
      navigate("/wallet?buy=false");
    } else {
      setShowConnectWallet(true);
    }
  };

  const getLevelImage = (levelNo) => {
    switch (levelNo) {
      case 0:
        return level1Icon;
      case 1:
        return level2Icon;
      case 2:
        return level3Icon;
      case 3:
        return level4Icon;
      case 4:
        return level5Icon;
      case 5:
        return level6Icon;
      case 6:
        return level7Icon;
      case 7:
        return level8Icon;
      case 8:
        return level9Icon;
      case 9:
        return level10Icon;
    }
  };

  const getFrameImage = (rankNo) => {
    switch (rankNo) {
      case 0:
        return rookie;
      case 1:
        return bronzeIcon;
      case 2:
        return silverIcon;
      case 3:
        return goldIcon;
      case 4:
        return platinumIcon;
      case 5:
        return diamondIcon;
      case 6:
        return blueDiamondIcon;
      case 7:
        return crownDiamondIcon;
    }
  };

  const truncateName = (name = '', maxLength = 8) => {
    if (name.length <= maxLength) return name;
    return `${name.slice(0, maxLength)}...`;
  };

  return (
    <div className={styles.welcomeContainer2} style={{ backdropFilter: "blur(20px) !important" }}>
      <div className={styles.welcomeBox}>
        <div className={styles.left}>
          <div className={styles.lTop}>
            <div className={styles.ppBox}>
              <div className={styles.profileContainer}>
                <div className={styles.ppl}>
                  <img
                    src={getFrameImage(user.rank)}
                    className={styles.ppLevel}
                    alt="ppLevel"
                  />
                  <div className={styles.profilepic}>
                    <img
                      src={userProfileImg || frogImg}
                      className={styles.frogImg}
                      alt="frogImg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.welBox}>
              <div className={styles.welText}>Welcome</div>
              <div className={styles.nameBox}>
                <div className={styles.pepeName}>
                  {truncateName(user?.name || user?.telegramUsername || user?.userID)}
                </div>
                {user.kycCompleted && <img
                  src={bluecheck}
                  className={styles.checkIcon}
                  alt="verifiedIcon"
                />
                }
                <img
                  src={getLevelImage(user.level)}
                  className={styles.levelIcon}
                  alt="levelIcon"
                />
              </div>
            </div>
          </div>
          <div className={styles.lBottom}>
            <div
              onClick={() => {
                navigate("/personal");
              }}
              className={styles.levelText}
            >
              {user.rankName}
            </div>
            <div className={styles.levelscore}>
              <div className={styles.rank}>
                <span>{user.nextRankUpAt - user.invitesToRankUp}</span>/
                {user.nextRankUpAt}{" "}
              </div>
              <div className={styles.progressBox}>
                <div className={styles.circle}></div>
                <div
                  className={styles.greenBar}
                  style={{
                    width: `${((user.nextRankUpAt - user.invitesToRankUp) /
                      user.nextRankUpAt) *
                      100
                      }%`,
                  }}
                >
                  <div className={styles.barRank}>
                    <IoStar className={styles.starIcon} />
                  </div>
                </div>
              </div>
            </div>
            <IoIosArrowForward
              className={styles.arrowIcon}
              onClick={() => {
                navigate("/personal");
              }}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.connectBox}>
            <img src={connIcon} className={styles.connIcon} alt="connIcon" />
            <div className={styles.conn} onClick={handleConnect}>
              {connectedAddress !== ""
                ? connectedAddress?.slice(0, 6)
                : "CONNECT"}
            </div>
          </div>
          <div className={styles.credBox}>
            <div className={styles.coinImg}>
              <img
                className={styles.PEPECoinImg}
                src={PEPEcoin}
                alt="PEPECoin"
              />
            </div>
            <div className={styles.creds}>
              <div className={styles.pepeText}>PEPE Credits</div>
              <div className={styles.pepeCount}
                style={{ display: user?.pepeCredits != 0 ? "flex" : "none", }}
              >
                {getReadableNumber(user?.pepeCredits)}
              </div>
              <Link
                to="/wallet?buy=true"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className={styles.buyButton}>BUY</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBar;
