import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { FaCommentsDollar, FaGamepad } from "react-icons/fa6";
import { BsCheckCircleFill } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";
import { TbHeartHandshake } from "react-icons/tb";
import { RiBarChart2Fill } from "react-icons/ri";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

const BottomButtons = () => {
  const { pathname } = useLocation();
  const page = pathname.split("/")[1];
  const { taskPending, setTaskPending } = useContext(AuthContext);
  const vibrateOnClick = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50); // vibration on tap
    } else if (window.Telegram?.WebApp)
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
  };

  useEffect(() => {
    if (page === "task") setTaskPending(false);
  }, [page])

  return (
    <div className="bottomButtons">
      <Link
        to="/?noload=true"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div
          onClick={() => {
            vibrateOnClick();
          }}
          style={{
            display: "flex",
            gap: "2px",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <FaGamepad
            style={{
              color: page === "" ? "#FFBD14" : "white",
              fontSize: "30px",
              textAlign: "center",
            }}
          />
          <button className="btn-text">PLAY</button>
        </div>
      </Link>
      <Link to="/task" style={{ textDecoration: "none", color: "inherit" }}>
        <div
          onClick={() => {
            vibrateOnClick();
          }}
          style={{
            display: "flex",
            gap: "2px",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "12px",
            marginBottom: "10px",
            position: "relative",
          }}
        >
          {taskPending && (
            <div
              className="blink"
              style={{
                position: "absolute",
                width: "10px",
                height: "10px",
                borderRadius: "10px",
                backgroundColor: "red",
                top: 0,
                right: 2,
              }}
            />
          )}
          <BsCheckCircleFill
            style={{
              color: page === "task" ? "#FFBD14" : "white",
              fontSize: "23px",
              textAlign: "center",
            }}
          />
          <button className="btn-text" style={{ marginTop: "2px" }}>
            TASKS
          </button>
        </div>
      </Link>
      <Link to="/profile" style={{ textDecoration: "none", color: "inherit" }}>
        <div
          onClick={() => {
            vibrateOnClick();
          }}
          style={{
            display: "flex",
            gap: "2px",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <BsFillPersonFill
            style={{
              color: page === "profile" ? "#FFBD14" : "white",
              fontSize: "28px",
              textAlign: "center",
            }}
          />
          <button className="btn-text">PROFILE</button>
        </div>
      </Link>
      <Link to="/stats" style={{ textDecoration: "none", color: "inherit" }}>
        <div
          onClick={() => {
            vibrateOnClick();
          }}
          style={{
            display: "flex",
            gap: "2px",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <RiBarChart2Fill
            style={{
              color: page === "stats" ? "#FFBD14" : "white",
              fontSize: "28px",
              textAlign: "center",
            }}
          />
          <button className="btn-text">STATS</button>
        </div>
      </Link>
      <Link to="/friend" style={{ textDecoration: "none", color: "inherit" }}>
        <div
          onClick={() => {
            vibrateOnClick();
          }}
          style={{
            display: "flex",
            gap: "2px",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <TbHeartHandshake
            style={{
              color: page === "friend" ? "#FFBD14" : "white",
              fontSize: "28px",
              textAlign: "center",
            }}
          />
          <button className="btn-text">FRIENDS</button>
        </div>
      </Link>
    </div>
  );
};

export default BottomButtons;
