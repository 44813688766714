import React, { useContext, useEffect, useState } from "react";
import "./tasks.css";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { MdCheck } from "react-icons/md";
import star from "../../../media/rotating_star2.gif";
import moving_star from "../../../media/moving_stars.webp";
import SocialAccounts from "../../component/Tasks-screen/SocialAccounts";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import { IoCheckbox } from "react-icons/io5";
import currency from "../../../media/silver-pepe-bg.png";
import telegramImg from "../../../media/teligramIcon.png";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import { useSpring, animated } from "@react-spring/web";
import SwitchableTaskCard from "../../component/Tasks-screen/SwitchableTaskCard";
import { UserContext } from "../../../context/UserContext";
import { useTonAddress } from "@tonconnect/ui-react";
import WalletModal from "../WalletModal";

const Tasks = ({ userID, setIsOpen, setUserCredits }) => {
  const [tasks, setTasks] = useState([]);
  const [page, setPages] = useState("friend");
  const [visible, setVisible] = useState(false);
  const { showConnectWallet, setShowConnectWallet } = useContext(UserContext);
  const connectedAddress = useTonAddress();

  const topAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(90%)",
      });
    },
    config: { duration: 300 },
  });

  const leftAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 300 },
  });

  const rightAnimation = useSpring({
    from: { transform: "translateX(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 300 },
  });

  useEffect(() => {
    setPages("task");
  }, []);

  return (
    <div>
      <div className="taskContainer">
        <div className="overlay">
          <div className="radial1"></div>
          <div className="radial2"></div>

          <div className="taskContent">
            <animated.div style={{ ...topAnimation }}>
              <div className="pfBarr2">
                <ProfileBar
                  showConnectWallet={showConnectWallet}
                  setShowConnectWallet={setShowConnectWallet}
                  connectedAddress={connectedAddress}
                />
              </div>
            </animated.div>
            <div className="iconSection">
              {/* <div>
                <iframe src="https://giphy.com/embed/sWFYgYFjHGugleQdO7" style={{width: "100%", height: "200px", position: "absolute", objectFit: "cover", zIndex: -1}}  frameBorder="0"></iframe>
              </div> */}
              {/* <img src={moving_star} style={{position:'absolute', width: "100%", height: "200px", objectFit: "cover"}} /> */}
              <img alt="trophy" src={star} className="starImg" />
            </div>
            <div className="socialAccouts3rx">
              <SocialAccounts screen="social" title="SOCIAL ACCOUNTS" />
              <SwitchableTaskCard
                screen="x"
                screenOptions={["x", "telegram", "youtube"]}
                title="AVAILABLE TASKS"
                icons={true}
              />
              {/* last card */}
              <animated.div style={{ ...leftAnimation }}>
                <SocialAccounts screen="extra" title="EXTRA TASKS" />
              </animated.div>
            </div>
          </div>
        </div>
      </div>

      <WalletModal
        connectedAddress={connectedAddress}
        showConnectWallet={showConnectWallet}
        setShowConnectWallet={setShowConnectWallet}
      />
      <div className="bottomNav">
        <BottomButtons page={page} setPages={setPages} />
      </div>
    </div>
  );
};

function SocialMediaCardSkeleton() {
  return (
    <div className="card socialMediaCardContainer" style={{ height: "65px" }}>
      <div className="shimmer" />
    </div>
  );
}

export default Tasks;
