import React, { useState, useEffect } from "react";
import styles from "./Faq.module.css";
import faq1 from "../../../media/1_faq.png";
import faq2 from "../../../media/2_faq.png";
import faq3 from "../../../media/3_faq.png";
import faq4 from "../../../media/4_faq.png";
import faq5 from "../../../media/5_faq.png";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";

const faqs = [
  {
    id: 1,
    image: faq1,
    title: "What is Pepe's Frogbar?",
    content: `Pepe's Frogbar is a comprehensive platform designed to launch a globally recognized meme
    coin. We leverage a robust business and marketing strategy with the goal of propelling our
    token into the top 3 on CoinMarketCap.`,
  },
  {
    id: 2,
    image: faq2,
    title: "How You Can Benefit:",
    content: (
      <ul>
        <li>
          <strong>Earn Tap Credits:</strong> Play our Tap-to-Earn game to
          accumulate Tap Credits, granting access to a portion of the private
          sale.
        </li>
        <li>
          <strong>Direct Purchase:</strong> Buy Pepe Credits through our DApp
          for immediate access to a larger private sale allocation.
        </li>
      </ul>
    ),
  },
  {
    id: 3,
    image: faq3,
    title: "Understanding Tap & Pepe Credits:",
    content: (
      <ul>
        <li>
          <strong>Tap Credits:</strong> Earn free rewards through our game, with
          5% of the token supply allocated for Tap Credits holders.
        </li>
        <li>
          <strong>Pepe Credits:</strong> Purchase through the DApp for higher
          value and access to 46% of the token supply in the private sale.
        </li>
      </ul>
    ),
  },
  {
    id: 4,
    image: faq4,
    title: "Converting Credits to Tokens:",
    content: `Both Tap Credits and Pepe Credits can be seamlessly swapped for the native meme coin
through a convenient swap protocol before launch`,
  },
  {
    id: 5,
    image: faq5,
    title: "Token Listing:",
    content: `The token listing is scheduled for late 2024, with the exact date to be announced. Our goal
is to strategically coincide with the market's bull run for optimal impact.`,
  },
];

const Faq = () => {
  const [visible, setVisible] = useState(false);

  const faqAnimation = useSpring({
    from: { transform: "translateX(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(100%)",
      });
    },
    config: { duration: 400 },
  });

  const headAnimation = useSpring({
    from: { transform: "translateX(80%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(100%)",
      });
    },
    config: { duration: 250 },
  });

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div className={styles.body}>
      <div className={styles.overlay}>
        <div className={styles.radial1}></div>
        <div className={styles.radial2}></div>
        <div className={styles.statContent}>
          <div className={styles.headBox}>
            <animated.div style={{ ...headAnimation }}>
              <div className={styles.faqTop}>
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <IoIosArrowBack className={styles.backArrow} />
                </Link>
                <div>FAQs</div>
              </div>
            </animated.div>
          </div>
          <div className={styles.faqContainer}>
            {faqs.map((faq) => (
              <animated.div key={faq.id} style={{ ...faqAnimation }}>
                <div className={styles.faqBox}>
                  <img src={faq.image} alt="FAQ" className={styles.faqImage} />
                  <div className={styles.faqTitle}>{faq.title}</div>
                  <div className={styles.faqContent}>{faq.content}</div>
                </div>
              </animated.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
