import React, { useContext, useEffect, useRef, useState } from "react";
import "./calendar.css";
import { BsCheckLg } from "react-icons/bs";
import cld from "../../media/cld.png";
import { MdClose } from "react-icons/md";
import toast from "react-hot-toast";
import { useSpring, animated } from "@react-spring/web";
import Confetti from "react-confetti";
import { IoCloseCircle } from "react-icons/io5";
import Popup from "../Shared/CongratsPopup/Popup";
import silverPepe from "../../media/newSilverPepe.png";
import { UserContext } from "../../context/UserContext";

const CalendarModal = ({ isOpen, setIsOpen }) => {
  const modalRef = useRef();
  const [firstClaim, setFirstClaim] = useState(true);
  const [visible, setVisible] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { user, setShowReturnPopup } = useContext(UserContext);
  const { showReturnPopup, returnCreditReward, consecutiveLoginDays } = user;

  const profileBoxAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0.1 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });

      await next({ backdropFilter: "blur(40px)" });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(0%)",
      });
    },
    config: { duration: 400 },
  });

  const buyBoxAnimation = useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(0%)",
      });
    },
    config: { duration: 400 },
  });

  useEffect(() => {
    setShowConfetti(true);
    setShowReturnPopup(false);
  }, []);

  useEffect(() => {
    // setShowConfetti(true);
    // console.log("confetti",showConfetti);
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    if (firstClaim) {
      if (showReturnPopup) {
        toast.success("Daily reward claimed!");
      }
      setFirstClaim(false);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const startIdx = 7 * Math.floor((consecutiveLoginDays - 1) / 7) + 1;

  // useEffect(() => {
  //   setVisible(false);
  // }, []);

  return (
    <div className="modal" style={{ display: isOpen ? "flex" : "none" }}>
      {showConfetti && (
        <div className="confetti">
          {" "}
          <Confetti width={820} recycle={false} />
        </div>
      )}
      <div className="congrats-box">
        <Popup
          src={silverPepe}
          greet="CONGRATULATIONS"
          greetMsg={`You have won ${Math.min(3500, 500 * (consecutiveLoginDays)) +((consecutiveLoginDays) % 7 === 0 ? 20000 : 0)} Pepe Tap Credits`}
        />
      </div>
      <animated.div style={{ ...buyBoxAnimation }}>
        <div className="modal-content" ref={modalRef}>
          <div
            onClick={() => {
              setIsOpen(false);
              setShowConfetti(false);
            }}
            style={{
              position: "absolute",
              right: 10,
              marginRight: "12px",
              top: 10,
              color: "white",
            }}
          >
            <IoCloseCircle className="closeIcon_ax" />
          </div>
          <img className="cld-img" src={cld} alt="calendar image" />
          <p className="modal-tilte">
            Accumulate Tap Credits by logging <br /> into the game daily
          </p>
          <div className="all-cd-card">
            {Array(8)
              .fill(0)
              .map((_, idx) => {
                if (idx + startIdx <= consecutiveLoginDays) {
                  return (
                    <div key={idx} className="green-div">
                      <p>
                        <BsCheckLg />
                      </p>
                    </div>
                  );
                }

                if (idx === 6) {
                  return (
                    <div key={idx} className="decorated-dark-div">
                      <div className="reward-text_23">
                        7th CONSECUTIVE DAY LOGIN & WIN
                      </div>
                      <p>DAY {idx + startIdx}</p>
                      {/* <p>+{Math.min(3500, 500 * (startIdx + idx)) + ((startIdx + idx) % 7 === 0 ? 20000 : 0)}</p> */}
                      <p>+20k</p>
                    </div>
                  );
                }
                return (
                  <div key={idx} className="dark-div">
                    <p>DAY {idx + startIdx}</p>
                    <p>
                      +
                      {Math.min(3500, 500 * (startIdx + idx)) +
                        ((startIdx + idx) % 7 === 0 ? 20000 : 0)}
                    </p>
                  </div>
                );
              })}
          </div>
          <div>
            <p className="bottom-text">COME BACK TOMORROW FOR MORE</p>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default CalendarModal;
