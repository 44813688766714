import React from "react";
import "./customPopup.css";
import { MdClose } from "react-icons/md";
const CustomPopup = ({ setShowRulesModal }) => {
  return (
    <div className="RulesModal">
      <div className="customPopupContent">
        <div className="customModalTop">
          <span className="customPopupTitle">Rules</span>
          <span className="closeIcon" onClick={() => setShowRulesModal(false)}>
            <MdClose className="closeIcon" />
          </span>
        </div>
        <div className="customModalDivider"></div>
        <div>
          <div className="customModalContentBox">
            <p>
              You will get 3 tickets per day. New tickets will be generated at
              00:00 UTC.
            </p>
          </div>
          <div className="customModalContentBox">
            <p>There is a cooldown period of 30mins between each ticket.</p>
          </div>
          <div className="customModalContentBox">
            <p>
              The first 3 assets to match out of 12 will earn you a prize as
              below.
            </p>
          </div>
        </div>
        <div className="customModalBottom">
          <div className="bottomBox">3 Bronze = 100,000 Credits</div>
          <div className="bottomBox">3 SILVER = 250,000 Credits</div>
          <div className="bottomBox">3 GOLD = 500,000 Credits</div>
          <div className="bottomBox">3 FROG = 2,000,000 Credits</div>
        </div>
      </div>
    </div>
  );
};

export default CustomPopup;
