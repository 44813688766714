import React from "react";
import "./unsupported.css";

export default function Unsupported() {
  return (
    <div
      className="glow-text"
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      <h1 style={{ fontSize: "60px", position: "absolute", top: 10 }}>
        Unsupported
      </h1>
      <h3
        style={{
          color: "darkorange",
          position: "absolute",
          bottom: 5,
          left: 5,
          textDecoration: "none",
        }}
      >
        Psst....coming soon on web💥
      </h3>
    </div>
  );

  // return (
  //     <div className='main-container'>
  //         <div className='maintenance-image-container'>
  //         </div>
  //     </div>
  // )
}
