import React from "react";
import step2Img from "../../../media/stepImg2.png";


import newstep2 from "../../../media/lateststep2.png";
import "./Step2.css";
const Step2 = () => {
  return (
    <>
      <div className="step2_container">
        <p className="step2_Text">
          <b>Earn and Engage</b> <br/>
          Participate in our Telegram game and{" "}
          <span className="step_2Green">farm</span> Tap Credits and secure your
          spot in the upcoming <span style={{ color: "yellow" }}>airdrop</span>.
        </p>
        <div className="step2_imageContainer">
          <img src={newstep2} alt="step2Img" className="step2Image" />
        </div>
      </div>
    </>
  );
};

export default Step2;
