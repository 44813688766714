import React, { useEffect, useState, useContext } from "react";
import coinImg from "../../../media/tap_credits_border.png";
import { useTonAddress } from "@tonconnect/ui-react";
import { useNavigate } from "react-router-dom";
import { TON_EXPLORER } from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";

const CreditBalance = ({ handleDisconnect, setWalletConnect }) => {
  const navigate = useNavigate();
  const connectedAddress = useTonAddress();
  const [balance, setBalance] = useState(0);
  const { userCredits } = useContext(UserContext);

  useEffect(() => {
    if (connectedAddress == "") {
      setWalletConnect(false);
    } else {
      getBalance(connectedAddress);
    }
  }, [connectedAddress]);

  const getBalance = async (address) => {
    const getBalResponse = await fetch(
      `${TON_EXPLORER}/getWalletInformation?address=${address}`,
      {
        method: "GET",
        headers: {
          "X-API-Key":
            "ec2cd059d5d4ec929eaa7936442b3938cce4792a6834faa229c2100060f37c67",
        },
      }
    );
    if (getBalResponse.ok) {
      const response = await getBalResponse.json();
      const { result } = response;
      const tonBalance = parseInt(result.balance) / 1e9;
      setBalance(tonBalance);
    } else {
      setBalance(0);
    }
  };

  return (
    <div
      style={{
        margin: "5px",
        padding: "0",
      }}
    >
      <div
        style={{
          textAlign: "right",
        }}
        onClick={handleDisconnect}
      >
        <a
          href="/"
          style={{
            color: "white",
            fontSize: "20px",
            color: "white",
            fontFamily: "Poppins",
          }}
        >
          Disconnect
        </a>
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          src={coinImg}
          alt=""
          height="66px"
          width="66px"
          textAlign="center"
        />
        <p
          style={{
            color: "#80CA07",
            fontWeight: "500",
            fontSize: "14px",
            textAlign: "center",
            fontFamily: "Oswald",
            marginTop: "-5px",
          }}
          className=""
        >
          TAP CREDIT BALANCE
        </p>
        <p
          style={{
            fontWeight: "500",
            fontSize: "33px",
            textAlign: "center",
            fontFamily: "Oswald",
            color: "white",
            marginTop: "-20px",
          }}
        >
          {userCredits.toLocaleString()}
        </p>
      </div>
      <div
        className="tablePart"
        style={{
          margin: "-15px 10px",
        }}
      >
        <table>
          {connectedAddress && (
            <tr>
              <td>Wallet address</td>
              <td>{`${connectedAddress?.slice(
                0,
                10
              )}...${connectedAddress?.slice(
                connectedAddress?.length - 4,
                connectedAddress?.length - 1
              )}`}</td>
            </tr>
          )}
          <tr>
            <td>TON balance</td>
            <td>{balance}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default CreditBalance;
