import React, { useContext, useEffect, useState } from "react";
import { FaXTwitter } from "react-icons/fa6";
import { IoCheckbox } from "react-icons/io5";
import currency from "../../../media/silver-pepe-bg.png";
import { ImYoutube } from "react-icons/im";
import { FaTelegram } from "react-icons/fa";
import tweeterIcon from "../../../media/x-icon.png";
import youtubeIcon from "../../../media/youtube-fill.png";
import telegramIcon from "../../../media/telegram.png";
import telegramSmall from "../../../media/teligramIcon.png";
import tweeterSamll from "../../../media/twitter.svg.png";
import youtubeSmall from "../../../media/youtubeIcon.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";
import { AuthContext } from "../../../context/AuthContext";
import { getMobileOperatingSystem } from "../../../utils/helpers";

function screenImg(taskName = "") {
  const splitted = taskName.split(" ");
  if (splitted.indexOf("YOUTUBE") !== -1) return youtubeIcon;
  else if (splitted.indexOf("X") !== -1) return tweeterIcon;
  else return telegramIcon;
}

function screenColor(taskName = "") {
  const splitted = taskName.split(" ");
  if (splitted.indexOf("YOUTUBE") !== -1) return "#EA4025";
  else if (splitted.indexOf("X") !== -1) return "black";
  else return "#2A7CD4";
}

const SocialAccounts = ({ screen, title }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isRedeemLoading, setIsRedeemLoading] = useState(false);
  const { setUserCredits } = useContext(UserContext);
  const { jwt } = useContext(AuthContext);

  async function handleTaskCompletion(taskID, redirectUrl) {
    try {
      setIsRedeemLoading(true);
      const response = await fetch(`${SERVER_ENDPOINT}/v1/task/redeem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ taskID }),
      });
      const parsedResponse = await response.json();
      if (parsedResponse.status === "SUCCESS") {
        // put in timeout for adding delay in completion ui
        setTimeout(
          () =>
            setTasks((tasks) => {
              return tasks.map((task) => {
                if (task.taskID === parsedResponse.data.taskID) {
                  task.completed = true;
                }
                return task;
              });
            }),
          5000
        );
        setUserCredits(
          (credits) => credits + parseInt(parsedResponse.data.reward)
        );
      } else throw new Error("Failed to redeem task");
    } catch (error) {
      console.log(error);
    } finally {
      setIsRedeemLoading(false);
      if(getMobileOperatingSystem() == "iOS") {
        window.location.href = redirectUrl;
      } else {
        window.open(redirectUrl);
      }
    }
  }

  useEffect(() => {
    async function fetchTasks() {
      try {
        setIsLoading(true);
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen }),
        });
        const parsedResponse = await response.json();
        console.log(parsedResponse.data);
        setTasks(parsedResponse.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTasks();
  }, [screen]);

  return (
    <div
      className="SocialAccountBox"
      style={{
        padding: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p
            className="accountCardTitle"
            style={{
              textAlign: "left",
              color: "#89eaff",
              fontSize: "17px",
              fontFamily: "oswald",
              padding: "0px 3px",
            }}
          >
            {title}
          </p>
        </div>
      </div>

      <hr />
      {tasks &&
        tasks.map(({ taskID, screen, completed, name, reward, url }) => {
          return (
            <div
              key={taskID}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 5px",
              }}
              onClick={() => handleTaskCompletion(taskID, url)}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div>
                  <IoCheckbox
                    color={completed ? "green" : "white"}
                    fontSize="25px"
                  />
                </div>
                <div
                  style={{
                    background: screenColor(name),
                    padding: screenColor(name) == "black" ? "9px" : "8px",
                    borderRadius: "50%",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={screenImg(name)}
                    alt="tweeter"
                    height="20px"
                    width="20px"
                  />
                </div>
                <div>
                  <p style={{ fontSize: "14px", color: "white" }}>{name}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "white", fontSize: "15px" }}>
                  +{reward / 1000}K
                </p>
                <div>
                  <img src={currency} alt="" height="40px" width="40px" />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SocialAccounts;
