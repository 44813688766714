import React from "react";
import "./Step4.css";

const Step4 = () => {
  return (
    <div className="step4_container">
      <div className="textBox">
        <p className="step4_Text">
          <b>Stay Informed</b> 
          <br/> Follow us for the latest updates on the{" "}
          <span style={{ color: "green" }}>Pepe's FrogBar</span> ecosystem.
        </p>
      </div>
      <div className="step4_icon">
        <div className="allIcons">
          <div className="loadingIconDiv">
            <a href="https://t.me/pepefrogbar">
              <img
                className="loadingIcon"
                src="https://i.ibb.co/3hKskrt/teligram-Icon.png"
                alt="icons"
              />
            </a>
          </div>
          <div className="loadingIconDiv">
            <a href="youtube://user/@frogbar">
              <img
                className="loadingIcon"
                src="https://i.ibb.co/n81ngZ0/youtube-Icon.png"
                alt="icons"
              />
            </a>
          </div>
          <div className="loadingIconDiv">
            <a href="twitter://user?screen_name=pepefrogbar">
              <img
                className="loadingIcon"
                src="https://i.ibb.co/XJ6vWbJ/twitter-svg.png"
                alt="icons"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
