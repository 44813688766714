import React, { useState, useRef } from "react";
import "./coinAnimation.css";
import { useNavigate } from "react-router-dom";

const CoinAnimationButton = ({
  ButtonComponent,
  text,
  result,
  renderIcon,
  navigationRoute,
  customClass,
  onClaim,
  updatedCredits,
  isCreditsLoaded,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleClick = () => {
    if (isButtonDisabled) return;
    setIsAnimating(true);
    setIsButtonDisabled(true);

    if (navigator.vibrate) {
      navigator.vibrate(50);
    } else if (window.Telegram?.WebApp) {
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    }

    const newCredits = updatedCredits;

    if (onClaim) {
      onClaim(newCredits);
    }

    setTimeout(() => {
      setIsAnimating(false);
      navigate(`${navigationRoute}`);
    }, 3000);
  };

  React.useEffect(() => {
    if (isCreditsLoaded) {
      setIsButtonDisabled(false);
    }
  }, [isCreditsLoaded]);

  return (
    <div className="button-container">
      <div
        className={`button-wrapper ${isAnimating ? "animating" : ""}`}
        ref={buttonRef}
      >
        <button
          className={`custom-button ${customClass}`}
          onClick={handleClick}
          disabled={isButtonDisabled}
        >
          {isButtonDisabled ? "Loading...." : `${text} ${result}`}
        </button>
      </div>
      {isAnimating && (
        <div className="coin-container">
          {[...Array(50)].map((_, index) => (
            <img key={index} src={renderIcon} alt="coin" className="coin" />
          ))}
        </div>
      )}
    </div>
  );
};

export default CoinAnimationButton;
