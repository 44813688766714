import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="PolicyContainer">
      <h1 className="privacy-title">Privacy Policy</h1>
      <div className="policyWrapper">
        <section>
          <h2>1. Terms and Definitions</h2>
          <p>
            1.1. <strong>Telegram</strong> – Telegram Messenger Inc (also “we”).
          </p>
          <p>
            1.2. <strong>Platform</strong> – The Telegram Bot Platform.
          </p>
          <p>
            1.3. <strong>Developer</strong> – The operator and maintainer of
            Pepe's Frogbar Bot.
          </p>
          <p>
            1.4. <strong>Pepe's Frogbar</strong> – The bot developed by the
            Developer, available on the Telegram Platform (also “Bot” or
            “Service”).
          </p>
          <p>
            1.5. <strong>User</strong> – The person accessing Pepe's Frogbar via
            their Telegram account (also “you”).
          </p>
          <p>
            1.6. <strong>Policy</strong> – This document, governing the
            relationship between Pepe's Frogbar and the User.
          </p>
        </section>

        <section>
          <h2>2. General Provisions</h2>
          <p>
            2.1. This Policy applies to the usage of Pepe's Frogbar Bot on
            Telegram and governs the collection, storage, processing, and
            protection of User data.
          </p>
          <p>
            2.2. This Policy supplements the Telegram Privacy Policy and
            Telegram Bot Terms.
          </p>
          <p>
            2.3. By using Pepe's Frogbar Bot, you agree to this Policy. If you
            do not agree, please cease use immediately.
          </p>
          <p>
            2.4. This Policy may be updated at any time, and it is the User’s
            responsibility to review and agree to any changes before continuing
            use of the Bot.
          </p>
          <p>
            2.5. The Bot is designed primarily to allow users to earn credits by
            playing a tapping game, purchasing credits via a connected TON
            wallet, or performing specific tasks such as subscribing to Telegram
            channels, YouTube channels, liking videos, and other tasks.
          </p>
        </section>

        <section>
          <h2>3. Disclaimers</h2>
          <p>
            3.1. Pepe's Frogbar Bot is an independent third-party service and is
            not affiliated with or endorsed by Telegram.
          </p>
          <p>
            3.2. Users acknowledge that they have read and agreed to the
            Telegram Bot Terms and other terms set by the Developer.
          </p>
          <p>
            3.3. Users are responsible for ensuring compliance with local laws,
            including providing accurate information, adhering to age
            restrictions, and fulfilling legal obligations.
          </p>
          <p>
            3.4. The Developer does not verify the accuracy of User-provided
            information.
          </p>
        </section>

        <section>
          <h2>4. Collection of Personal Data</h2>
          <p>4.1. Pepe's Frogbar Bot collects the following data:</p>
          <ul>
            <li>Name</li>
            <li>Telegram username</li>
            <li>TON wallet address</li>
            <li>Profile image</li>
            <li>Email</li>
            <li>Country</li>
          </ul>
          <p>
            4.2. Automatic Login and Data Collection: Upon login, Telegram API
            retrieves and stores the User's Telegram username and name
            automatically in the Bot’s database to manage User profiles and
            enable in-game features.
          </p>
          <p>4.3. The Bot may collect additional data, such as:</p>
          <ul>
            <li>Messages sent by Users</li>
            <li>Files uploaded by Users</li>
            <li>
              Task completion data (e.g., YouTube channel subscriptions,
              Telegram channel subscriptions, likes on YouTube videos)
            </li>
          </ul>
          <p>
            4.4. The Bot may receive transactional data when a User connects
            their TON wallet for the purchase of Pepe Credits.
          </p>
          <p>
            4.5. Anonymous Data: The Bot may collect anonymized data, such as
            usage statistics, which are not linked to identifiable individuals.
          </p>
        </section>

        <section>
          <h2>5. Processing of Personal Data</h2>
          <p>5.1. The Bot processes User data for the following purposes:</p>
          <ul>
            <li>Enabling in-game features and account management</li>
            <li>
              Facilitating tasks that Users perform to earn credits (e.g.,
              subscribing to Telegram/YouTube channels, liking videos)
            </li>
            <li>Managing purchases via TON wallet for credits</li>
            <li>
              Providing and improving game functionality through performance
              metrics
            </li>
            <li>Supporting the referral system for User invites</li>
          </ul>
          <p>
            5.2. User data will not be shared with third parties, except in the
            following circumstances:
          </p>
          <ul>
            <li>Authorized by the User</li>
            <li>Required by law</li>
            <li>
              As necessary to process transactions related to TON wallet usage
            </li>
          </ul>
          <p>
            5.3. The Bot does not monetize or sell User data for purposes
            unrelated to its core features.
          </p>
        </section>

        <section>
          <h2>6. Methods for Earning Credits</h2>
          <p>6.1. Users can earn Pepe Credits in the following ways:</p>
          <ul>
            <li>
              <strong>Free methods:</strong>
            </li>
            <ul>
              <li>Playing the tapping game and collecting coins</li>
              <li>
                Completing tasks such as subscribing to Telegram channels,
                subscribing to YouTube channels, liking YouTube videos, or other
                tasks assigned by the Bot
              </li>
              <li>Inviting other users to join the Bot via referral links</li>
            </ul>
            <li>
              <strong>Paid methods:</strong>
            </li>
            <ul>
              <li>
                Connecting their TON wallet to the Bot and purchasing Pepe
                Credits via the package system
              </li>
            </ul>
          </ul>
          <p>
            6.2. Credits earned through free methods cannot be exchanged for
            real currency but may contribute to the User’s overall Pepe Credits,
            which may be redeemed as cryptocurrency once the Pepe token is
            launched on an exchange.
          </p>
        </section>

        <section>
          <h2>7. Data Protection</h2>
          <p>
            7.1. The Developer employs robust security measures to ensure the
            integrity and confidentiality of personal data, including:
          </p>
          <ul>
            <li>Encryption of sensitive data (e.g., TON wallet details)</li>
            <li>
              Secure storage of all personal and transactional information
            </li>
            <li>
              Prevention of unauthorized access, modification, or deletion of
              data
            </li>
          </ul>
          <p>
            7.2. User data is handled in compliance with applicable local and
            international data protection laws.
          </p>
        </section>

        <section>
          <h2>8. User Rights and Obligations</h2>
          <p>
            8.1. <strong>User Rights:</strong>
          </p>
          <ul>
            <li>
              Users have the right to request a copy of the personal data
              collected and stored by the Bot.
            </li>
            <li>
              Users may request the deletion of their personal data, except
              where retention is permitted by law for specific reasons (e.g.,
              compliance with legal obligations, defense of legal claims,
              fulfillment of tax obligations).
            </li>
            <li>
              Users may amend, restrict, or object to the processing of their
              personal data.
            </li>
            <li>
              Users may revoke any previously given consent at any time, and may
              cease using the Bot.
            </li>
            <li>
              Users may lodge complaints with the relevant data protection
              authorities if they believe their rights are being infringed.
            </li>
          </ul>
          <p>
            8.2. <strong>User Obligations:</strong>
          </p>
          <ul>
            <li>
              Users must provide accurate and up-to-date information when
              submitting data to the Bot.
            </li>
            <li>
              Users must cooperate with the Developer when exercising their data
              rights (e.g., responding to verification requests).
            </li>
            <li>
              Users must adhere to the terms outlined in this Policy and the
              Developer’s other policies.
            </li>
          </ul>
          <p>
            8.3. <strong>Developer Obligations:</strong>
          </p>
          <ul>
            <li>
              The Developer will respond to data requests promptly, within 30
              days.
            </li>
            <li>
              The Developer will ensure compliance with all relevant privacy
              regulations and Telegram Developer Terms.
            </li>
            <li>
              The Developer will provide Users with a copy of this Policy and
              make it accessible via the Bot or other means.
            </li>
          </ul>
        </section>

        <section>
          <h2>9. Purchases and Credits</h2>
          <p>
            9.1. Users may purchase Pepe Credits only by connecting their TON
            wallet to the Bot and purchasing packages.
          </p>
          <p>
            9.2. No payment methods other than the TON wallet are accepted for
            purchasing credits.
          </p>
          <p>
            9.3. The Bot processes all transactions securely, and no sensitive
            payment data is stored outside of what is necessary for transaction
            processing.
          </p>
        </section>

        <section>
          <h2>10. Referral System</h2>
          <p>
            10.1. Users can earn additional credits by inviting others to join
            Pepe's Frogbar.
          </p>
          <p>
            10.2. Referral links are tracked by the Bot, and rewards are
            distributed based on successful referrals.
          </p>
          <p>
            10.3. The Developer reserves the right to monitor and limit referral
            activities to prevent abuse.
          </p>
        </section>

        <section>
          <h2>11. Changes to This Privacy Policy</h2>
          <p>
            11.1. This Policy may be updated periodically, and the latest
            version will be posted in the Bot or on the Bot’s homepage.
          </p>
          <p>
            11.2. Any changes to the Policy will become effective upon
            publication, and continued use of the Bot constitutes acceptance of
            the updated terms.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
