import React, { createContext, useEffect, useState } from "react";
import useWebSocket from "../hooks/useWebhook";
import { SERVER_ENDPOINT } from "../utils/constants";

export const AuthContext = createContext();




export const AuthContextProvider = ({ children }) => {
    const [jwt, setJwt] = useState();
    const [taskPending, setTaskPending] = useState(false);

    const { sendMessage } = useWebSocket();
    useEffect(() => {
        async function fetchTaskStatus() {
            try {
                if (jwt) {
                    const res = await fetch(`${SERVER_ENDPOINT}/v1/task/status`, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    });
                    const parsed = await res.json();
                    if (parsed.status === "SUCCESS")
                        setTaskPending(parsed.data.pendingTasks);
                    else throw new Error("Failed to fetch task status");
                }
            } catch (error) {
                console.log(error);
            }
        }
        if (jwt) {
            sendMessage(JSON.stringify({ type: "presence", jwt }));
            fetchTaskStatus();
        }
    }, [jwt])

    return (
        <AuthContext.Provider value={{ jwt, setJwt, taskPending, setTaskPending }}>
            {children}
        </AuthContext.Provider>
    )
}