import React from "react";

const Wallet = () => {
  return (
    <div>
      <div className="walletDiv">
        <p>
          Welcome, <span>99th</span>
        </p>
        {/* <div>
                    <p>CONNECT</p>
                    <img src="https://i.ibb.co/R6sQz56/walleticon.png" alt="" />
                </div> */}
      </div>
    </div>
  );
};

export default Wallet;
