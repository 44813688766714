import React, { useEffect, useRef, useState } from "react";
import "./loadingIndex.css";
import { useSpring, animated } from "@react-spring/web";
import { getMobileOperatingSystem } from "../../utils/helpers";

const LoadingIndex = ({ setShowLoading }) => {
  const CopyAnimation = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: { duration: 1400 },
    loop: { reverse: true },
  });

  const openUrl = (url) => {
    if(getMobileOperatingSystem() == "iOS") {
      window.location.href = url;
    } else {
      window.open(url);
    }
  }

  return (
    <div className="loadingPageBgImage">
      {/* {isPlaying ? <video ref={videoRef} id="background-video" key="video2" muted autoPlay>
          <source src={"loading2.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video> : <video ref={videoRef} id="background-video" key="video1" muted autoPlay>
          <source src={"loading1.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>} */}
      <div className="color-overlay"></div>
      <div className="content">
        <div className="tapToEarnMainDiv">
          <div>
            {/* <p className="hand-icon">👋</p>
            <p className="tap-text">TAP TO EARN</p> */}
          </div>

          {/* <animated.div style={{ ...CopyAnimation }}> */}
          {/* <div className={`loading-enter-btn`}>
            <p>ENTER</p>
          </div> */}
          {/* </animated.div> */}

          <div className="allIcons">
            <div className="loadingIconDiv"  onClick={() => {openUrl("https://t.me/pepefrogbar")}}>
              <a>
                <img
                  className="loadingIcon"
                  src="https://i.ibb.co/3hKskrt/teligram-Icon.png"
                  alt="icons"
                />
              </a>
            </div>
            <div className="loadingIconDiv" onClick={() => {openUrl("https://www.youtube.com/@frogbar")}}>
              <a>
                <img
                  className="loadingIcon"
                  src="https://i.ibb.co/n81ngZ0/youtube-Icon.png"
                  alt="icons"
                />
              </a>
            </div>
            <div className="loadingIconDiv" onClick={() => {openUrl("https://x.com/pepefrogbar")}}>
              <a>
                <img
                  className="loadingIcon"
                  src="https://i.ibb.co/XJ6vWbJ/twitter-svg.png"
                  alt="icons"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingIndex;
