import React, { useContext } from "react";

import { rankToStringMapping } from "../../../utils/constants";
import { getReadableNumber } from "../../../utils/helpers";
import { UserContext } from "../../../context/UserContext";

const GamePackageCard = ({
  rankImg,
  levelImg,
  handlePurchase,
  owned,
  userPackage,
  tonUsdValue,
  tokenPrice,
  connectedAddress,
}) => {
  const { name, packageID, price, currency, rank, level } = userPackage;
  const { setShowConnectWallet } = useContext(UserContext);
  const price2 = price;

  return (
    <div
      className={
        owned ? "gamePackageContainerSelected" : "gamePackageContainer"
      }
    >
      <div
        style={{
          padding: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            className="gameCardTitle"
            style={{ color: "#F7F7F7", fontFamily: "oswald" }}
          >
            {name}
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontFamily: "oswald",
                  fontSize: "12px",
                }}
              >
                {rankToStringMapping[rank]}
              </span>
              <span>
                <img src={rankImg} alt="" height="16px" width="16px" />
              </span>
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontFamily: "oswald",
                  fontSize: "12px",
                }}
              >
                {level + 1}
              </span>
              <span>
                <img src={levelImg} alt="" height="16px" width="16px" />
              </span>
            </span>
          </div>
        </div>
        <span
          style={{
            color: "white",
            fontFamily: "oswald",
            fontWeight: "500",
            fontSize: "16px",
            margin: "5px 0px",
            padding: "5px 0px",
            borderBottom: "1px solid #FFFFFF",
            lineHeight: "23.23px",
          }}
        >
          PEPE Credits: {getReadableNumber(price2 / tokenPrice)}
        </span>
      </div>
      {/* last line */}
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#BCFF78",
            fontFamily: "oswald",
            fontWeight: "500",
            fontSize: "14px",
            marginLeft: "10px",
          }}
        >
          Price: ${price2} | {(price2 / tonUsdValue).toFixed(9)} TON
        </span>
        <button
          className={owned ? "gamePackageButtonInactive" : "gamePackageButton"}
          onClick={
            !connectedAddress
              ? () => {
                  setShowConnectWallet(true);
                }
              : !owned
              ? () => handlePurchase(packageID, price / tonUsdValue)
              : () => {}
          }
        >
          Buy
        </button>
      </span>
    </div>
  );
};

export default GamePackageCard;

// import React, { useContext } from "react";

// import { rankToStringMapping } from "../../../utils/constants";
// import { getReadableNumber } from "../../../utils/helpers";
// import { UserContext } from "../../../context/UserContext";

// const GamePackageCard = ({
//   rankImg,
//   levelImg,
//   handlePurchase,
//   owned,
//   userPackage,
//   tonUsdValue,
//   tokenPrice,
//   connectedAddress,
// }) => {
//   const { name, packageID, price, currency, rank, level } = userPackage;
//   const { setShowConnectWallet } = useContext(UserContext);
//   const price2 = 0.01;
//   return (
//     <div
//       className={
//         owned ? "gamePackageContainerSelected" : "gamePackageContainer"
//       }
//     >
//       <div
//         style={{
//           padding: "8px",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <span
//             className="gameCardTitle"
//             style={{ color: "#F7F7F7", fontFamily: "oswald" }}
//           >
//             {name}
//           </span>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               gap: "10px",
//             }}
//           >
//             <span
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 gap: "10px",
//               }}
//             >
//               <span
//                 style={{
//                   color: "white",
//                   fontFamily: "oswald",
//                   fontSize: "12px",
//                 }}
//               >
//                 {rankToStringMapping[rank]}
//               </span>
//               <span>
//                 <img src={rankImg} alt="" height="16px" width="16px" />
//               </span>
//             </span>
//             <span
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 gap: "10px",
//               }}
//             >
//               <span
//                 style={{
//                   color: "white",
//                   fontFamily: "oswald",
//                   fontSize: "12px",
//                 }}
//               >
//                 {level + 1}
//               </span>
//               <span>
//                 <img src={levelImg} alt="" height="16px" width="16px" />
//               </span>
//             </span>
//           </div>
//         </div>
//         <span
//           style={{
//             color: "white",
//             fontFamily: "oswald",
//             fontWeight: "500",
//             fontSize: "16px",
//             margin: "5px 0px",
//             padding: "5px 0px",
//             borderBottom: "1px solid #FFFFFF",
//             lineHeight: "23.23px",
//           }}
//         >
//           PEPE Credits: {getReadableNumber(price2 / tokenPrice)}
//         </span>
//       </div>
//       {/* last line */}
//       <span
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <span
//           style={{
//             color: "#BCFF78",
//             fontFamily: "oswald",
//             fontWeight: "500",
//             fontSize: "14px",
//             marginLeft: "10px",
//           }}
//         >
//           Price: ${price2} | {(price2 / tonUsdValue).toFixed(9)} TON
//         </span>
//         <button
//           // className={owned ? "gamePackageButtonInactive" : "gamePackageButton"}
//           className={owned ? "gamePackageButton" : "gamePackageButtonInactive"}

//           onClick={
//             !connectedAddress
//               ? () => {
//                   console.log(true);
//                   setShowConnectWallet(true);
//                 }
//               : !owned
//               ? () => handlePurchase(packageID, price2 / tonUsdValue)
//               : () => {}
//           }
//         >
//           Buy
//         </button>
//       </span>
//     </div>
//   );
// };

// export default GamePackageCard;
