import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SERVER_ENDPOINT } from '../utils/constants';

const useServerTimeSync = () => {
    const [serverTime, setServerTime] = useState(null);

    useEffect(() => {
        const fetchServerTime = async () => {
            try {
                const response = await fetch(`${SERVER_ENDPOINT}/server-timestamp`, { method: "GET" });
                const parsedResponse = await response.json();
                if (parsedResponse.status === "SUCCESS") setServerTime(moment(parsedResponse.data.serverTime).utc());
                else throw new Error("Failed to fetch timestamp")

            } catch (error) {
                console.log(error);
            }
        };
        fetchServerTime();
        const intervalId = setInterval(fetchServerTime, 60000); // Sync every minute
        return () => clearInterval(intervalId);
    }, []);

    return moment().utc().diff(serverTime, "seconds") < 60;
};

export default useServerTimeSync;
