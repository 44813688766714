import React, { useEffect, useRef, useState } from "react";
import connIcon from "../../media/connIcon.png";
import { Grid } from "react-loader-spinner";
import "./confirmTxModal.css";

const ConfirmTxModal = ({showConfirmModal, setShowConfirmModal, connectedAddress}) => {

  
  return (
      showConfirmModal && connectedAddress !== "" && (
    <div className="walletModalpfx" style={{display: showConfirmModal ? "flex" : "none" }} >

    <div style={{ zIndex: 1000 }}>
          <div className="confirm-tx-container">
          <div className="confirm-tx-content">
            <div style={{width: "100%", textAlign: "right"}} onClick={() => {setShowConfirmModal(false)}}>x</div>
            <div className="wallet-content">
              <Grid
                visible={true}
                height="80"
                width="80"
                color="rgb(17 105 197 / 67%)"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass="grid-wrapper"
                />
              <h1>Confirming Transaction</h1>
              <p>
                Waiting for the transaction to confirm. This might take upto 30 - 40 secs. Do not change the screen.
              </p>
              <div style={{width: "5px", 'height': "100px"}}></div>
            </div>
          </div>
        </div>
      
    </div>
    </div>
  )

  );
};

export default ConfirmTxModal;