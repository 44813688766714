import React, { useContext, useEffect, useState } from "react";
import { FaXTwitter } from "react-icons/fa6";
import { IoCheckbox } from "react-icons/io5";
import currency from "../../../media/silver-pepe-bg.png";
import { ImYoutube } from "react-icons/im";
import { FaTelegram } from "react-icons/fa";
import tweeterIcon from "../../../media/x-icon.png";
import youtubeIcon from "../../../media/youtube-fill.png";
import telegramIcon from "../../../media/telegram.png";
import telegramSmall from "../../../media/teligramIcon.png";
import tweeterSamll from "../../../media/twitter.svg.png";
import youtubeSmall from "../../../media/youtubeIcon.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";
import { AuthContext } from "../../../context/AuthContext";
import { getMobileOperatingSystem } from "../../../utils/helpers";

function screenImg(screenName) {
  if (screenName == "youtube") return youtubeIcon;
  else if (screenName == "x") return tweeterIcon;
  else return telegramIcon;
}

const SwitchableTaskCard = ({ icons, title, screenOptions }) => {
  const [screen, setScreen] = useState(screenOptions[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedeemLoading, setIsRedeemLoading] = useState(false);
  const [blinkTele, setBlinkTele] = useState(false);
  const [blinkYt, setBlinkYt] = useState(false);
  const [blinkX, setBlinkX] = useState(false);
  const [tasks, setTasks] = useState([]);
  const { setUserCredits, user } = useContext(UserContext);
  const { jwt } = useContext(AuthContext);

  async function handleTaskCompletion(taskID, redirectUrl) {
    try {
      setIsRedeemLoading(true);
      const response = await fetch(`${SERVER_ENDPOINT}/v1/task/redeem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ taskID }),
      });
      const parsedResponse = await response.json();
      if (parsedResponse.status === "SUCCESS") {
        // put in timeout for adding delay in completion ui
        setTimeout(
          () =>
            setTasks((tasks) => {
              return tasks.map((task) => {
                if (task.taskID === parsedResponse.data.taskID) {
                  task.completed = true;
                }
                return task;
              });
            }),
          5000
        );
        setUserCredits(
          (credits) => credits + parseInt(parsedResponse.data.reward)
        );
      } else throw new Error("Failed to redeem task");
    } catch (error) {
      console.log(error);
    } finally {
      setIsRedeemLoading(false);
      if (getMobileOperatingSystem() == "iOS") {
        window.location.href = redirectUrl;
      } else {
        window.open(redirectUrl);
      }
      //   window.location.href = redirectUrl;
    }
  }

  useEffect(() => {
    async function fetchTasks() {
      try {
        setIsLoading(true);
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen }),
        });
        const parsedResponse = await response.json();
        setTasks(parsedResponse.data);

        console.log(parsedResponse.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTasks();
  }, [screen]);

  useEffect(() => {
    async function blinkScreenTasks(screen) {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/task/updateUserScreenTask`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
              screen,
            }),
          }
        );
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS") {
          if (screen == 'telegram') setBlinkTele(parsedResponse.data.pendingTasks);
          if (screen == 'youtube') setBlinkYt(parsedResponse.data.pendingTasks);
          if (screen == 'x') setBlinkX(parsedResponse.data.pendingTasks);
        }
          
        else throw new Error("Failed to fetch task status");
      } catch (error) {
        console.log(error);
      }
    }

    blinkScreenTasks("telegram");
    blinkScreenTasks("youtube");
    // blinkScreenTasks("x");
  }, []);

  return (
    <div
      className="SocialAccountBox"
      style={{
        padding: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p
            className="accountCardTitle"
            style={{
              textAlign: "left",
              color: "#89eaff",
              fontSize: "17px",
              fontFamily: "oswald",
              padding: "0px 3px",
            }}
          >
            {title}
          </p>
        </div>
        {icons && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                background: screen === "x" ? "black" : "#ffffff4b",
                padding: "10.5px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "3rem",
                marginRight: "5px",
              }}
              onClick={() => setScreen(screenOptions[0])}
            >
              <img
                src={tweeterSamll}
                alt="tweeter"
                height="18px"
                width="18px"
              />
            </div>
            <div
              style={{
                background: screen === "telegram" ? "#2A7CD4" : "#ffffff4b",
                padding: "10px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "3rem",
                marginRight: "5px",
                position: "relative",
              }}
              onClick={() => { 
                setScreen(screenOptions[1]);
                setBlinkTele(false);
              }}
            >
              {blinkTele && (
                <div
                  className="blink"
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    borderRadius: "10px",
                    backgroundColor: "red",
                    right: 2,
                    top: 0,
                  }}
                />
              )}
              <img
                src={telegramSmall}
                alt="tweeter"
                height="18px"
                width="18px"
              />
            </div>
            <div
              style={{
                background: screen === "youtube" ? "#EA4025" : "#ffffff4b",
                padding: "10px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "3rem",
                position: "relative",
              }}
              onClick={() => { 
                setScreen(screenOptions[2]);
                setBlinkYt(false);
              }}
            >
              {blinkYt && (
                <div
                  className="blink"
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    borderRadius: "10px",
                    backgroundColor: "red",
                    right: 2,
                    top: 0,
                  }}
                />
              )}
              <img src={youtubeIcon} alt="tweeter" height="20px" width="20px" />
            </div>
          </div>
        )}
      </div>

      <hr />
      {tasks &&
        tasks.map(({ taskID, completed, name, reward, url }) => {
          return (
            <div
              key={taskID}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 5px",
              }}
              onClick={() => handleTaskCompletion(taskID, url)}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div>
                  <IoCheckbox
                    color={completed ? "green" : "white"}
                    fontSize="25px"
                  />
                </div>
                <div
                  style={{
                    background:
                      screen == "x"
                        ? "black"
                        : screen == "telegram"
                        ? "#2A7CD4"
                        : "#EA4025",
                    padding: "9px",
                    borderRadius: "50%",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={screenImg(screen)}
                    alt="tweeter"
                    height="20px"
                    width="20px"
                  />
                </div>
                <div>
                  <p style={{ fontSize: "14px", color: "white" }}>{name}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "white", fontSize: "15px" }}>
                  +{reward / 1000}K
                </p>
                <div>
                  <img src={currency} alt="" height="40px" width="40px" />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SwitchableTaskCard;
