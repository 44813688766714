import React, { useContext, useEffect, useRef, useState } from "react";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import toast from "react-hot-toast";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const prizes = ["bronze", "silver", "gold", "frog"];
const val = {
  bronze: 100000,
  silver: 250000,
  gold: 500000,
  frog: 2000000,
};

const Puzzle = () => {
  const [result, setResult] = useState("");
  const [boxes, setBoxes] = useState([]);
  const [isClicked, setIsClicked] = useState(Array(12).fill(false));
  const [countScratch, setCountScratch] = useState(0);
  const { jwt } = useContext(AuthContext);
  const hasRun = useRef(false);
  const navigate = useNavigate();

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function cardClicked(value, index) {
    if (isClicked[index] == true) return;
    if (value == result) {
      console.log(countScratch, value, result);
      if (countScratch + 1 == 3) {
        toast.success(result);
        async function addCredits() {
          try {
            const credit = val[result];
            const response = await fetch(
              `${SERVER_ENDPOINT}/v1/puzzle/addCredits`,
              {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                  tap: credit,
                }),
              }
            );
            const parsedResponse = await response.json();
            console.log(parsedResponse);
            
          } catch (error) {
            console.log(error);
          }
        }
        addCredits();
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setCountScratch(countScratch + 1);
    }
    const newClickedStates = [...isClicked];
    newClickedStates[index] = true;
    setIsClicked(newClickedStates);
  }

  useEffect(() => {
    async function fetchResult() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/puzzle/claimNewOutcome`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const parsedResponse = await response.json();
        setResult(parsedResponse.data);
        const index = prizes.indexOf(parsedResponse.data);
        console.log("res", parsedResponse, parsedResponse.data, index);
        if (parsedResponse.status == "FAILED") {
          if (parsedResponse.error.lastPlayTime) {
            toast.error(
              30 -
                moment()
                  .utc()
                  .diff(
                    moment.utc(parsedResponse.error.lastPlayTime),
                    "minutes"
                  )
            );
            setTimeout(() => {
              navigate("/?noload=true");
            }, 3000);
          } else if (parsedResponse.error.playCount) {
            toast.error(parsedResponse.error.playCount);
            setTimeout(() => {
              navigate("/?noload=true");
            }, 3000);
          }
        }
        let array = [];
        for (let i = 0; i < 12; i++) {
          if (i < 6) array.push(prizes[index]);
          else if (i < 8) array.push(prizes[(index + 1) % 4]);
          else if (i < 10) array.push(prizes[(index + 2) % 4]);
          else array.push(prizes[(index + 3) % 4]);
        }

        setBoxes(shuffle(array));
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          navigate("/?noload=true");
        }, 3000);
      }
    }
    if (!hasRun.current) {
      hasRun.current = true;
      fetchResult();
    }
  }, []);
  return (
    <div style={{ display: "flex", flexWrap: "wrap", width: "300px" }}>
      {boxes.length > 0 &&
        boxes.map((value, index) => {
          return (
            <div
              style={{
                flex: "0 0 33.33%",
                background: "#ffffff",
                height: "75px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #000",
                boxSizing: "border-box",
              }}
              onClick={() => cardClicked(value, index)}
            >
              {" "}
              {isClicked[index] ? value : ""}{" "}
            </div>
          );
        })}
    </div>
  );
};

export default Puzzle;
