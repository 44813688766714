
const getReadableNumber = (number) => {
    if(number) {
        let counter = 0;
    
        while(number > 1000 && counter < 3) {
            number = number / 1000;
            counter ++;
        }
    
        let suffix = '';
        if(counter == 0) {
            suffix = '';
        } else if(counter == 1) {
            suffix = 'K';
        } else if(counter == 2) {
            suffix = "M";
        } else {
            suffix = 'B';
        }
    
        return number.toFixed(2) + suffix;
    } else {
        return "0";
    }
}

function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
  
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
  
    return 'unknown';
}

module.exports = { getReadableNumber, getMobileOperatingSystem };