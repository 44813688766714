import React, { useEffect, useState } from "react";
import Wallet from "../wallet";
import CreditsMoney from "../../../media/silver-pepe-bg.png";
import Trophy from "../../../media/trophy.png";
import "../palay.css";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import "./ranking.css";
import frogIcon from "../../../media/frogImg.png";

export default function Ranking({ user }) {
  const { name } = user;
  const [ranking, setRanking] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPages] = useState("stats");

  useEffect(() => {
    async function fetchRanking() {
      try {
        setIsLoading(true);
        const res = await fetch(`${SERVER_ENDPOINT}/v1/user/ranking`, {
          method: "GET",
        });
        const parsedRes = await res.json();
        if (parsedRes.status === "SUCCESS") {
          setRanking(parsedRes.data);
        } else throw new Error("Failed to fetch rankings");
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchRanking();
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#141418",
        height: "100vh",
        overflowY: "scroll",
      }}
      className="playFixedDiv"
    >
      <Wallet name={name} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img alt="trophy" width="182px" height="182px" src={Trophy} />
      </div>
      <div
        style={{
          display: "flex",
          width: "92%",
          gap: "14px",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          paddingBottom: "120px",
        }}
      >
        {isLoading
          ? Array(10)
              .fill(0)
              .map(() => <RankCardSkeleton />)
          : ranking.map((user, idx) => (
              <RankCard
                idx={idx + 1}
                userID={user.userID}
                telegramUsername={user.telegramUsername}
                credits={user.credits}
              />
            ))}
        {error && (
          <div style={{ color: "white" }}>
            Hmm....rankings seem to have a problem
          </div>
        )}
      </div>
    </div>
  );
}

function RankCard({ userID, credits, idx, telegramUsername }) {
  return (
    <div
      style={{
        color: "white",
        height: "56px",
        borderRadius: "45px",
        backgroundColor: "rgba(30, 28, 37, 1)",
        display: "flex",
        gap: "20px",
        alignItems: "center",
        padding: "8px",
      }}
    >
      <div
        style={{
          height: "40px",
          width: "40px",
          borderRadius: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            idx === 1
              ? "rgba(255, 184, 0, 1)"
              : idx === 2
              ? "white"
              : idx === 3
              ? "rgba(134, 77, 36, 1)"
              : null,
        }}
      >
        {idx <= 3 ? <img width="30px" height="30px" src={frogIcon} /> : idx}
      </div>
      <div>
        <div>{telegramUsername ? telegramUsername : userID}</div>
        <div style={{ display: "flex", gap: "6px" }}>
          <img alt="credit money" width="25px" src={CreditsMoney} />
          <div>{credits}</div>
        </div>
      </div>
    </div>
  );
}

function RankCardSkeleton() {
  return (
    <div
      className="card"
      style={{
        height: "56px",
        borderRadius: "45px",
        backgroundColor: "rgba(30, 28, 37, 1)",
        padding: "8px",
      }}
    >
      <div className="shimmer" />
    </div>
  );
}
