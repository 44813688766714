import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./Personal.module.css";
import Confetti from "react-confetti";
import { useSpring, animated } from "@react-spring/web";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import { IoCloseCircle } from "react-icons/io5";
import bronzeIcon from "../../../media/bronze.png";
import silverIcon from "../../../media/silver.png";
import goldIcon from "../../../media/gold.png";
import platinumIcon from "../../../media/platinum.png";
import diamondIcon from "../../../media/diamond.png";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import rookie from "../../../media/rookie.png";
import level1 from "../../../media/level1.png";
import level2 from "../../../media/level2.png";
import level3 from "../../../media/level3.png";
import level4 from "../../../media/level4.png";
import level5 from "../../../media/level5.png";
import level6 from "../../../media/level6.png";
import level7 from "../../../media/level7.png";
import level8 from "../../../media/level8.png";
import level9 from "../../../media/level9.png";
import level10 from "../../../media/level10.png";
import pertap1 from "../../../media/pertap1.png";
import pertap2 from "../../../media/pertap2.png";
import pertap3 from "../../../media/pertap3.png";
import pertap4 from "../../../media/pertap4.png";
import pertap5 from "../../../media/pertap5.png";
import pertap6 from "../../../media/pertap6.png";
import pertap7 from "../../../media/pertap7.png";
import pertap8 from "../../../media/pertap8.png";
import pertap9 from "../../../media/pertap9.png";
import pertap10 from "../../../media/pertap10.png";
import { UserContext } from "../../../context/UserContext";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { AuthContext } from "../../../context/AuthContext";
import Popup from "../../Shared/CongratsPopup/Popup";
import party_popper from "../../../media/party_popper.png";
import silverPepe from "../../../media/newSilverPepe.png";


const ranks = [
  { rankName: "ROOKIE", task: "Start tapping", icon: rookie, energy: 3600, rank: 0 },
  {
    rankName: "BRONZE",
    task: "Invite 3 friends",
    icon: bronzeIcon,
    energy: 4000,
    rank: 1,
  },
  {
    rankName: "SILVER",
    task: "Invite 10 friends",
    icon: silverIcon,
    energy: 4500,
    rank: 2,
  },
  {
    rankName: "GOLD",
    task: "Invite 25 friends",
    icon: goldIcon,
    energy: 5000,
    rank: 3,
  },
  {
    rankName: "PLATINUM",
    task: "Invite 50 friends",
    icon: platinumIcon,
    energy: 7500,
    rank: 4,
  },
  {
    rankName: "DIAMOND",
    task: "Invite 100 friends",
    icon: diamondIcon,
    energy: 10000,
    rank: 5,
  },
  {
    rankName: "BLUE DIAMOND",
    task: "Invite 500 friends",
    icon: blueDiamondIcon,
    energy: 12500,
    rank: 6,
  },
  {
    rankName: "CROWN DIAMOND",
    task: "Invite 1000 friends",
    icon: crownDiamondIcon,
    energy: 15000,
    rank: 7,
  },
];

const levels = {
  0: { credits: pertap1, credspertap: 1, icon: level1, price: 0 },
  1: { credits: pertap2, credspertap: 2, icon: level2, price: 10000 },
  2: { credits: pertap3, credspertap: 3, icon: level3, price: 20000 },
  3: { credits: pertap4, credspertap: 4, icon: level4, price: 50000 },
  4: { credits: pertap5, credspertap: 5, icon: level5, price: 100000 },
  5: { credits: pertap6, credspertap: 6, icon: level6, price: 200000 },
  6: { credits: pertap7, credspertap: 7, icon: level7, price: 500000 },
  7: { credits: pertap8, credspertap: 8, icon: level8, price: 750000 },
  8: { credits: pertap9, credspertap: 9, icon: level9, price: 1000000 },
  9: { credits: pertap10, credspertap: 10, icon: level10, price: 1500000 },
};

const PersonalStat = () => {
  const [showBuy, setShowBuy] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [showCongratsPopup, setShowCongratsPopup] = useState(false);
  const [visible, setVisible] = useState(false);
  const [page, setPages] = useState("stats");
  const [showConfetti, setShowConfetti] = useState(false);
  const modalRef = useRef(null);
  const { userCredits, user, setUser, setUserCredits } =
    useContext(UserContext);
  const { jwt } = useContext(AuthContext);
  const [shownote, setShownote] = useState(false)


  const rankBoxAnimation = useSpring({
    from: { transform: "translateX(100vw)", opacity: 0 },
    to: { transform: "translateX(0vw)", opacity: 1 },
    config: { duration: 400 },
  });

  const levelBoxAnimation = useSpring({
    from: { transform: "translateX(-100vw)", opacity: 0 },
    to: { transform: "translateX(0vw)", opacity: 1 },
    config: { duration: 400 },
  });

  const buyBoxAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: showBuy ? "translateY(0%)" : "translateY(100%)",
      });
    },
    config: { duration: 400 },
  });

  const profileBoxAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0.1 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });

      // await next({ backdropFilter: "blur(40px)" });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(100%)",
      });
    },
    config: { duration: 300 },
  });

  const handleBuy = (level) => {
    setSelectedLevel(level);
    setShowBuy(true);
    console.log("Show Buy:", showBuy);
  };

  const handlePurchaseConfirm = async () => {
    try {
      if (user.level < parseInt(selectedLevel)) {
        const purchaseRes = await fetch(
          `${SERVER_ENDPOINT}/v1/user/level/updgrade`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const parsedRes = await purchaseRes.json();
        if (parsedRes.status === "SUCCESS") {
          
          setTimeout(() => {
            setShowBuy(false);
          }, 8000);
          setUser((user) => ({ ...user, level: parsedRes.data.level }));
          setUserCredits(parsedRes.data.credits);
          setShownote(true);
          setShowConfetti(true);
        } else throw new Error("Failed to updgrade level");
      } else throw new Error("Invalid level selected");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowBuy(false);
      console.log("clickedoutside");
    }
  };

  useEffect(() => {
    if (showBuy) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showBuy]);

  useEffect(() => {
    setVisible(true);
    setShowCongratsPopup(true);
    setShowConfetti(true);
  }, []);

  return (
    <div className={styles.body}>

      <div className={styles.overlay}>
        <div className={styles.radial1}></div>
        <div className={styles.radial2}></div>
      </div>
      {/* <div className="congratsBox">
            <Popup
              src={silverPepe}
              greet="CONGRATULATIONS"
              greetMsg="You have won 2000 Pepe Tap Credits"
            />
      </div> */}
      {showBuy && (
        <div className={styles.connectBuyContainer}>

          {shownote && <div className={styles.congratsBox}>
            {showConfetti && (
              <div className={styles.confetti}>
                {" "}
                <Confetti width={820} recycle={false} />
              </div>
            )}
            <Popup
              src={silverPepe}
              greet="CONGRATULATIONS"
              greetMsg={`You have been upgraded to level ${selectedLevel + 1}`}
            />
          </div>}
          <animated.div style={{ ...buyBoxAnimation }}>
            <div className={styles.connectBuyContent} ref={modalRef}>
              <div className={styles.BuyContent}>
                <div
                  style={{ width: "100%", textAlign: "right" }}
                  onClick={() => {
                    setShowBuy(false);
                  }}
                >
                  <IoCloseCircle className={styles.closebtn} />
                </div>
                <div className={styles.BuyIcon2}>
                  <img
                    src={levels[selectedLevel].icon}
                    className={styles.buyLevelIcon}
                    alt="Level Icon"
                  />
                </div>
                <h1>BUY {selectedLevel + 1}</h1>
                <p>
                  Earn {levels[selectedLevel].credspertap} credits per tap.
                  Increase your tapping ROI.
                </p>
                <div className={styles.price}>
                  Price: {levels[selectedLevel].price}
                </div>
                <div className={styles.balance}>
                  Current balance: {userCredits}
                </div>
                <button
                  className={styles.connectBuyButton}
                  onClick={() => handlePurchaseConfirm()}
                >
                  CONFIRM PURCHASE
                </button>
              </div>
            </div>
          </animated.div>
        </div>
      )}
      <div className={styles.statContent}>
        <animated.div style={{ ...profileBoxAnimation }}>
          <div className={styles.pfBar}>
            <ProfileBar />
          </div>
        </animated.div>
        <animated.div style={{ ...rankBoxAnimation }}>
          <div className={styles.rankBox}>
            <div className={styles.rankHeader}>
              <div className={styles.headElement}>RANK</div>
              <div className={styles.headElement3}>Energy</div>
              <div className={styles.headElement2}>TASK</div>
            </div>
            {ranks.map(({ rankName, rank, icon, energy, task }, index) => (
              <div
                key={index}
                className={`${styles.rankItem} ${
                  user.rank === rank && styles.yellowBackground
                }`}
              >
                <div className={styles.rankName}>
                  { (
                    <img
                      src={icon}
                      alt={`${rankName} icon`}
                      className={styles.rankIcon}
                    />
                  )}
                  {rankName}
                </div>
                <div className={styles.energyParent}>
                  <div className={styles.energycnt}>{energy}</div>
                </div>
                <div className={styles.rankTask}>{task}</div>
              </div>
            ))}
          </div>
        </animated.div>
        <animated.div style={{ ...levelBoxAnimation }}>
          <div className={styles.levelBox}>
            <div className={styles.levelHeader}>
              <div className={styles.lheadElement}>LEVEL</div>
              <div className={styles.lheadElement2}>CREDITS PER TAP</div>
            </div>
            {Object.keys(levels).map((level, index) => {
              const { credits, icon } = levels[level];
              level = parseInt(level);
              const currLevel = user.level;
              const isCurrLevel = user.level === level;
              const valid = level === currLevel + 1;
              return (
                <div
                  key={index}
                  className={`${styles.levelItem} ${
                    isCurrLevel && styles.yellowBackground
                  }`}
                >
                  <div className={styles.levelName}>
                    <img
                      src={icon}
                      alt={`${level} icon`}
                      className={styles.levelIcon}
                    />
                  </div>
                  <div className={styles.levelCredits}>
                    <img
                      src={credits}
                      alt={`${level} credit`}
                      className={styles.pertapIcon}
                    />
                  </div>
                  <div className={styles.levelBuy}>
                    <button
                      disabled={!valid}
                      className={styles.buyButton}
                      style={{ opacity: valid ? 1 : 0.5 }}
                      onClick={() => {
                        handleBuy(level);
                        console.log("clicked");
                      }}
                    >
                      BUY
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </animated.div>
        <div className={styles.bottomNav}>
          <BottomButtons page={page} setPages={setPages} />
        </div>
      </div>
    </div>
  );
};

export default PersonalStat;
